
import Vue from "vue"
import { GetInvitesItem } from "@/types/responses"
import SubHeader from "../global/SubHeader.vue"
export default Vue.extend({
	components: { SubHeader },
	name: "LogDialog",
	props: {
		logging: {
			type: Boolean,
		},
		item: Object as () => GetInvitesItem,
	},
	methods: {
		closeLog(): void {
			this.$emit("close", null)
		},
	},
})
